import { Entities } from '@inwink/entities/entities';
import { syncEntity } from '../../../../data/syncutils';
import type { ICommunityEntitySync } from './utils';
import { getTemplates } from '../../api/template';
// import { oldfieldtemplates } from '../../api/event';

export function syncFieldTemplate(args: ICommunityEntitySync) {
    const entities = ["Topic", "Article", "DocumentCommunity", "Vod", "Podcast", "IWEvent", "Member", "Company", 
        "companyoffering", "Group", "Forum", "Buyer", "OrderCommunity"];
    return syncFieldTemplates(entities, args);
}

export function syncFieldTemplates(templateNames: string[], args: ICommunityEntitySync) {
    let hasChanges = false;
    const updatedTemplates: Entities.IFieldTemplate[] = [];

    return syncEntity<Entities.IFieldTemplate>({
        requestMgr: args.communityRequests.apiFront,
        logger: args.logger,
        dataStore: args.communityData,
        force: args.force,
        apiCallProvider: () => {
            return getTemplates(args.communityRequests, templateNames);
        },
        collectionname: "fieldtemplates",
        itemkey: (r, s) => { return r.entityName === s.entityName; },
        itemcallback: (item) => {
            const existing = args.communityData.fieldtemplates.data.find((e) => e.entityName === item.entityName);
            if (!existing || !existing.workTemplate
                || (existing.workTemplate as any).version !== (item.workTemplate as any)?.version) {
                updatedTemplates.push(item);
                hasChanges = true;
            }
        },
        trackProgress: args.trackProgress,
        rewriteUrls: args.rewriteUrls
    }).then(() => {
        return {
            hasChanges,
            updatedTemplates
        };
    });
}
