import type { Entities } from '@inwink/entities/entities';
import type { ICommunityRequestManager } from '../../../services/apiaccessprovider.definition';

export function contentTemplateDatasourceV3(requestManager: ICommunityRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontCommunityEntityDataSource<Entities.IContentTemplate>(
            requestManager, "communitycontenttemplate", "CommunityContentTemplate"
        );
    });
}
