import type { Entities } from '@inwink/entities/entities';
import { States } from "@@services/services";
import { appSplashActions } from '@@services/splashservice';
import { getVisualConfiguration } from '@@data/templates';
import { getAccessManager } from '@@services/apiaccessprovider';
import { actions as i18nActions } from '@@services/i18nservice';
import { getCommunityRepository } from '@@modules/community/data/communitydata';
import { initCommunityActions } from './init';
import { syncCommunityActions } from './sync';

export const appsplashservice = "communitybootstrap";

function runBootstrap(
    isFromServer: boolean,
    location: States.ILocation,
    matches: States.ILocationMatch,
    eventid: string,
    tinyurl: string,
    forceload: boolean,
    dispatch,
    getState: () => States.IAppState
) {
    const state = getState();
    const preparePromise = state.community?.requestManagers
        ? Promise.resolve(state.community?.requestManagers)
        : getAccessManager().then((mgr) => mgr.getCommunityRequests(
            state.community.communityid, state.community?.tenantid
        ));

    return preparePromise.then((requestmgr) => {
        const communityId = state.community.communityid;

        const progresscallback = (arg: { progressPercent: number }) => {
            appSplashActions.progress(appsplashservice, {
                progress: arg.progressPercent
            })(dispatch);
        };

        const data = getCommunityRepository(communityId);
        return data.ready.then(() => {
            return syncCommunityActions.syncCommunityData(
                requestmgr,
                communityId,
                data,
                forceload,
                progresscallback
            )(dispatch, getState).then((syncResult) => {
                return initCommunityActions.setCurrentCommunity(
                    requestmgr, syncResult.detail, syncResult.communityData, false
                )(dispatch, getState).then(() => {
                    return i18nActions.autoinit()(dispatch, getState);
                }).then(() => {
                    if (!__SERVERSIDE__) {
                        return initCommunityActions.checkCommunityUser()(dispatch, getState);
                    }
                });
            });
        }).then(() => {
            // if (retries === 0) {
            //     return Promise.reject(new Error("oups"));
            // }
            appSplashActions.unregisterService(appsplashservice)(dispatch, getState);
        });
    });
}

export const bootstrapCommunityActions = {
    communityBootstrap(
        isFromServer: boolean,
        location: States.ILocation,
        matches: States.ILocationMatch,
        eventid: string,
        tinyurl: string,
        forceload = false
    ) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            let communityState = state.community;
            let visualconfig: Entities.IVisualConfigurationTemplate = null;

            if (communityState?.data) {
                visualconfig = getVisualConfiguration(communityState.data);
            }

            if (forceload) {
                if (state.rootwebsite.data) {
                    visualconfig = getVisualConfiguration(state.rootwebsite.data);
                }
                communityState = {
                    tinyUrl: tinyurl
                } as any;
                appSplashActions.setVisualConfig(visualconfig.splashscreen)(dispatch);
            }

            appSplashActions.registerService(appsplashservice, {
                hasError: false,
                progress: null,
                onRetry: () => {
                    // clearLastSync();
                    // eventBootstrap(false, tmpeventid, tinyurl, location, matches, false, dispatch, getState, retries + 1);
                    runBootstrap(
                        isFromServer,
                        location,
                        matches,
                        eventid,
                        tinyurl,
                        forceload,
                        dispatch,
                        getState
                    );
                }
            }, visualconfig)(dispatch, getState);

            return runBootstrap(
                isFromServer,
                location,
                matches,
                eventid,
                tinyurl,
                forceload,
                dispatch,
                getState
            ).then(null, () => {
                appSplashActions.progress(appsplashservice, {
                    progress: null,
                    hasError: true,
                    message: "sync.error"
                })(dispatch);
            });
        };
    }
};
