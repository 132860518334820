import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';
import { syncRecursiveEntity, getDate } from '../../../../data/syncutils';
import { ICommunityEntitySync } from './utils';
import { contentTemplateDatasourceV3 } from '../../api/contenttemplate';

export function syncContentTemplates(args: ICommunityEntitySync) {
    let templateState = "published";
    if (InWinkPreview) {
        templateState = "preview";
    }

    let hasChanges = false;
    const applications = [{ application: "community" }, { application: "communitywebsite" }, {application: "communitygroup"}];
    if (__SERVERSIDE__) {
        applications.push({ application: "community-server" });
    }
    const getExpr = (lastSync) => {
        const filters: any = {
            $or: applications,
            state: templateState
        };
        if (lastSync) {
            filters.validFrom = { $gt: getDate(moment(lastSync), true) };
        }
        const options: IInwinkEntityV3QueryOptions = {
            filters: filters,
            order: [{ by: "validFrom" }],
            selects: {
                $all: true
            }
        } as IInwinkEntityV3QueryOptions;

        return options;
    };

    return syncRecursiveEntity<Entities.IContentTemplate>({
        datasource: contentTemplateDatasourceV3(args.communityRequests.apiFront),
        getBaseOptions: getExpr,
        logger: args.logger,
        data: args.communityData,
        force: args.force,
        collectionname: "templates",
        itemcallback: () => {
            hasChanges = true;
        },
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}
