import { Entities } from '@inwink/entities/entities';
import { ICommunityRequests } from '../../../services/apiaccessprovider.definition';
import { transformWorkTemplate } from '../../../data/entitytemplate';

export function getTemplates(eventRequests: ICommunityRequests, entities: string[]): Promise<Entities.IFieldTemplate[]> {
    let url = `community/${eventRequests.communityid}/templates`;
    if (entities && entities.length) {
        url = url + "?entities=" + entities.shift() + (entities.length ? "&entities=" + entities.join("&entities=") : "");
    }
    return eventRequests.apiFront.getJson<{ [entity: string]: Entities.IEntityTemplateV3 }>(url).then((res) => {
        const templates: Entities.IFieldTemplate[] = [];
        const entityNames = Object.keys(res);
        entityNames.forEach((entity) => {
            const workTemplate = res[entity];
            const t: Entities.IFieldTemplate = {
                id: undefined,
                eventId: undefined,
                entityName: entity,
                template: transformWorkTemplate(res[entity]),
                workTemplate: res[entity]
            };
            (t as any).workTemplate = workTemplate;
            templates.push(t);
        });
        return templates;
    });
}

export function getTemplate(eventRequests: ICommunityRequests, entity: string): Promise<Entities.IEntityTemplate> {
    return eventRequests.apiFront.getJson<Entities.IEntityTemplateV3>(
        `community/${eventRequests.communityid}/template/${entity}`
    ).then((template) => transformWorkTemplate(template));
}
