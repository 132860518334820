/* eslint-disable newline-per-chained-call */
import type { ILogger } from '@inwink/logging';
import type { ICommunityRequests } from '../../../services/apiaccessprovider.definition';
import type { States } from '../../../services/services';
import { getLastSync } from '../../../data/globalsync';
import { getCommunityRepository } from '../data/communitydata';
import { metadataSyncActions } from '../../../services/appmetadataactions/sync';
import { ICommunityEntitySync } from './communitysync/utils';
import { getCommunityDetail } from '../api/communitydetail';
import { syncContentTemplates } from './communitysync/contenttemplates';
import { syncFieldTemplate } from './communitysync/entitytemplates';

const pendingSync: Record<string, Promise<any>> = {};

export function coreSync(
    logger: ILogger,
    communityRequests: ICommunityRequests,
    communityid: string,
    eData: States.ICommunityDataStore,
    force: boolean,
    dispatch,
    getState: () => States.IAppState,
    progressCallback?: (arg: { progressPercent: number }) => void,
    disableSave?: boolean,
    disableFrequentSyncCheck?: boolean
): Promise<{ hasChanges: boolean, communityData: States.ICommunityDataStore, detail: States.ICommunityDetail }> {
    let communityData = eData;
    let hasChanges = false;
    let syncCommunityDetail: States.ICommunityDetail;

    // const state = getState();
    const globalLastSync = getLastSync();

    if (pendingSync[communityid]) {
        logger.debug("DATA sync is already in progress");
        return pendingSync[communityid];
    }

    if (force) {
        communityData = getCommunityRepository(communityid, null, true);
    }

    const diff = (new Date() as any) - ((globalLastSync[communityid] as any) || 0);
    if (!force && diff < 5000 && !disableFrequentSyncCheck) {
        if (__SERVERSIDE__) {
            logger.debug("DATA too frequent sync...");
        } else {
            logger.info("DATA too frequent sync...");
        }
        return Promise.resolve({ hasChanges: false, communityData: communityData, detail: syncCommunityDetail });
    }

    communityData.syncInProgess = true;
    globalLastSync[communityid] = new Date();

    metadataSyncActions.isSyncingCommunityData(true)(dispatch);

    const progressSteps = [
        { name: "communitydetail", progress: 0, coeff: 0.2 },
        { name: "templates-community", progress: 0, coeff: 0.5 },
        { name: "templates-communitywebsite", progress: 0, coeff: 0.5 },
        { name: "fieldtemplates", progress: 0, coeff: 0.5 },
        { name: "save", progress: 0, coeff: 0.2 },
    ];

    const progress = {
        progressPercent: 0
    };

    const trackProgress = (arg: { collectionname: string, message?: string, progressPercent: number }) => {
        const current = progressSteps.filter((s) => s.name === arg.collectionname)[0];
        if (current) {
            current.progress = arg.progressPercent;
        }

        let currentProgress = 0;
        let currentCoeffs = 0;
        currentProgress = progressSteps.reduce((prev, c) => {
            currentCoeffs += c.coeff;
            return prev + (c.coeff * c.progress);
        }, currentProgress);
        progress.progressPercent = (currentProgress / currentCoeffs);

        if (progressCallback) progressCallback(progress);
    };

    let syncArgs: ICommunityEntitySync = null;
    communityData.syncInProgess = true;
    logger.verbose("DATA syncing event detail force:" + force);

    // let timeout = 5000;
    // if (__SERVERSIDE__) {
    //     timeout = 20000;
    // }

    const trackChanges = (res) => {
        hasChanges = hasChanges || !!res;
        return res;
    };

    const syncpromise = getCommunityDetail(communityRequests.apiFront, communityid).then((communitydetail) => {
        syncCommunityDetail = communitydetail;

        if (communityData.communityDetail && communityData.communityDetail.data.length) {
            const existing = communityData.communityDetail.data[0];
            Object.assign(existing, communitydetail);
            communityData.communityDetail.update(existing);
        } else {
            communityData.communityDetail.insert(communitydetail);
        }

        syncArgs = {
            communityDetail: communitydetail,
            configuration: communitydetail?.configuration,
            communityRequests: communityRequests,
            dispatch: dispatch,
            getState: getState,
            communityData: communityData,
            force: force,
            logger: logger,
            trackProgress: trackProgress,
            rewriteUrls: null
        };
        if (inwink.config.externalAssetsUrl && inwink.config.externalAssetsCDN) {
            syncArgs.rewriteUrls = [{
                source: inwink.config.externalAssetsUrl,
                target: inwink.config.externalAssetsCDN
            }];
        }
    }).then(() => {
        trackProgress({ collectionname: "communitydetail", progressPercent: 100 });
    }).then(() => {
        return Promise.all([
            syncFieldTemplate(syncArgs).then((res) => {
                syncArgs.updatedEntityTemplates = res.updatedTemplates;
                trackChanges(res.hasChanges);
            }),
            syncContentTemplates(syncArgs).then(trackChanges)
        ]);
    }).then((syncHasChanges) => {
        // hasChanges = hasChanges || syncHasChanges;
        logger.verbose("DATA core sync ok changes : " + hasChanges);
        let lastSync = communityData.lastsyncs.data.find((s) => s.key === "coresync");
        if (!lastSync) {
            lastSync = {
                key: "coresync",
                date: new Date()
            } as any;
            communityData.lastsyncs.insert(lastSync);
        } else {
            lastSync.date = new Date();
            communityData.lastsyncs.update(lastSync);
        }

        if (force) {
            let fullSync = communityData.lastsyncs.data.find((s) => s.key === "fullsync");
            if (!fullSync) {
                fullSync = {
                    key: "fullsync",
                    date: new Date()
                } as any;
                communityData.lastsyncs.insert(fullSync);
            } else if (force) {
                fullSync.date = new Date();
                communityData.lastsyncs.update(fullSync);
            }
        }

        if (communityData.fieldtemplates.data.length) {
            communityData.hasFullData = true;
        }
        communityData.hasData = true;

        return hasChanges;
    })
        .then(() => {
            if (!disableSave) {
                return communityData.save().then(() => {
                    trackProgress({ collectionname: "save", progressPercent: 100 });
                });
            }
        })
        .then(() => {
            pendingSync[communityid] = null;
            communityData.syncInProgess = false;
            metadataSyncActions.isSyncingCommunityData(false)(dispatch);
            // if (force) {
            //     eventActions.eventDataChanged(eventData)(dispatch);
            // } else if (hasChanges) {
            //     eventActions.eventDataChanged()(dispatch);
            // }

            globalLastSync[communityid] = new Date();

            return {
                hasChanges: hasChanges,
                communityData: communityData,
                detail: syncCommunityDetail
            };
        }, (err) => {
            logger.error("error syncing data", err);
            pendingSync[communityid] = null;
            communityData.syncInProgess = false;
            metadataSyncActions.isSyncingCommunityData(false)(dispatch);
            // if (hasChanges) {
            //     eventActions.eventDataChanged()(dispatch);
            // }
            return Promise.reject(err);
        });

    pendingSync[communityid] = syncpromise;

    return syncpromise;
}
