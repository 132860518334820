import { logging } from '@inwink/logging';
import {
    communityModule
} from '@@routes/appmodules';
import { ICommunityRequests } from '@@services/apiaccessprovider.definition';
import { States } from "@@services/services";

const logger = logging.getLogger("Data");

export const syncCommunityActions = {
    syncCommunityData(
        requests: ICommunityRequests,
        communityid: string,
        data: States.ICommunityDataStore,
        force = false,
        progressCallback: (arg: { progressPercent: number }) => void = null,
        disableFrequentSyncCheck?: boolean
    ) {
        return (dispatch, getState: () => States.IAppState) => {
            return communityModule().then((mod) => {
                return mod.coreSync(
                    logger,
                    requests,
                    communityid,
                    data,
                    force,
                    dispatch,
                    getState,
                    progressCallback,
                    false,
                    disableFrequentSyncCheck
                );
            });
        };
    },
};
